/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Link from '../../components/Link';
import { Button } from '..';
import Widget from './Widget';

export default props => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Widget title={translate('appInstallWidget.title')} {...props}>
      <Box sx={{ fontSize: [2, 18] }}>{translate('appInstallWidget.description')}</Box>
      <Box sx={{ mt: 3 }}>
        <Button as={Link} to="/qr-login" variant="secondary">
          {translate('appInstallWidget.action')}
        </Button>
      </Box>
    </Widget>
  );
};
