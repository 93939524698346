/** @jsx jsx */
import { jsx, Styled, Box, Flex } from 'theme-ui';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import AccountLayout from '../account/AccountLayout';
import { removeProfile } from '../state/session';
import { BookingWidget } from '../components/widgets';
import * as analytics from '../utils/analytics';
import QRCodeLoginWidget from '../components/widgets/QRCodeLoginWidget';
import { Button, Switch } from '../components';
import { useCallback } from 'react';
import { useState } from 'react';

const MyAccountPage = ({ pageContext }) => {
  analytics.usePageCategory('omat_sivut');
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const user = useSelector(state => state.session.user);
  const dispatch = useDispatch();

  const [areYouSure, setAreYouSure] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleDelete = useCallback(() => {
    setSubmitting(true);
    dispatch(removeProfile());
  }, [dispatch]);
  const onBackClick = useCallback(() => window.history.back(), []);

  return !user ? null : (
    <AccountLayout
      title={translate('account.header')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      darkMode={true}
      footer={false}
      showNavigation={false}
      sidebar={
        <Box>
          <BookingWidget sidebar />
          <QRCodeLoginWidget sx={{ mt: 4 }} sidebar />
        </Box>
      }
    >
      <Box sx={{ py: 4 }}>
        <Box>
          <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
            {translate('backButton')}
          </Button>
        </Box>
        <Styled.h1 sx={{ m: 0 }}>{translate('account.deleteProfileTitle')}</Styled.h1>
        <p>{translate('account.deleteProfile.description')}</p>
        <p>{translate('account.deleteProfile.additionalInfo')}</p>

        <Box sx={{ my: 5 }}>
          <Switch checked={areYouSure} onChange={e => setAreYouSure(e.target.checked)}>
            <span sx={{ fontWeight: 'medium' }}>{translate('account.deleteProfile.confirm')}</span>
          </Switch>
        </Box>

        <Flex sx={{ gap: 3 }}>
          <Button variant="secondary" sx={{ px: 5 }} onClick={onBackClick}>
            {translate('account.deleteProfile.return')}
          </Button>
          <Button disabled={!areYouSure || submitting} onClick={handleDelete}>
            {translate('account.deleteProfileTitle')}
          </Button>
        </Flex>
      </Box>
    </AccountLayout>
  );
};

export default MyAccountPage;
